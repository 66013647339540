<template>
  <v-row>
    <v-dialog :value="multiInvoicePaymentModal" persistent>
      <v-card>
        {{credit}}
        <v-form v-model="formIsValid" ref="form">
          <v-list class="d-flex flex-column align-center">
            <v-list-item v-for="(payment, index) of payments" :key="index" :class="{isPaid: payment.paid}">
              <v-list-item-content>
        <date-picker
        :outlined="true"
        label="Datum"
        :value="payment.date"
        :dateFormatted= payment.dateFormatted
        @changeDate="changeDate($event,index)"
        ></date-picker>
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  outlined
                  :rules="[rules.required]"
                  :value="payment.amount | toCurrency"
                  @input="payment.amount = $event"
                  label="Bedrag"
                  type="number"
                  hide-details
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-content>
                <v-radio-group
                  v-model="payment.payMethod"
                  @change="payment.paid=true"
                  :rules="[rules.required]"
                  row
                  class="ma-2"
                >
                  <v-radio class="ma-2" v-for="payMethod of payMethods" :key="payMethod" :label="payMethod" :value="payMethod" :disabled="payMethod==='iDEAL'"></v-radio>
                </v-radio-group>
              </v-list-item-content>
              <v-list-item-content>
                <v-icon @click="deletePayment(index)">mdi-delete</v-icon>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>Betaling toevoegen</v-list-item-content>
              <v-list-item-content class="align-end">
                <v-icon @click="addPayment">mdi-plus</v-icon>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-form>
        <v-card-actions fixed class="d-flex justify-center">          
          <v-btn color="error" tile @click="closeMultiInvoicePaymentModal"
            >Annuleer</v-btn
          >
          <v-btn color="success" tile @click="insertPayments">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import datePicker from "../components/datePicker"
export default {
  name: "multiInvoicePaymentModal",
  props: {
    multiInvoicePaymentModal: Boolean,
    invoices: Array
  },
  data() {
    return {
      payments: [],
      totalPaid: 0,
      formIsValid: false,
      rules: {
        required: value => !!value || 0 || null || "Veld verplicht.",
        counter: value => value.length <= 20 || "Max 20 karakters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Onjuist e-mailadres."
        }
      }
    }
  },
  methods: {
    changeDate(date,index){
this.payments[index].date = date
this.payments[index].dateFormatted = this.formatDate(date)
    },
    addPayment() {
        this.payments.push({
          amount: this.debit,
          payMethod: "",
          date: new Date(Date.now()).toISOString().split("T")[0],
          dateFormatted: this.formatDate(new Date(Date.now()).toISOString().split("T")[0]),
          paid: false
        })
    },
    deletePayment(index) {
this.payments.splice(index, 1)
    },
    insertPayments() {
      this.$refs.form.validate()
      if (this.formIsValid) {
          this.payments.forEach(payment => {
            let amount = payment.amount
            amount =
              typeof amount === "string"
                ? amount.replace(",", ".")
                : amount.toString().replace(",", ".")
            payment.amount = parseFloat(amount)
            payment.paid = true
          })
          this.$emit("insert-payments", this.payments)
      }
    },
    closeMultiInvoicePaymentModal() {
      this.$emit("close-multi-invoice-payment-modal")
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
  },
  computed: {
    grossTotal() {
        return this.invoices.reduce((acc, cur) => acc + cur.grossTotal,0)
    },
    credit(){
      let credit
    credit = this.payments.filter(payment=>payment.paid==true).reduce((acc,cur)=> (acc*100 + parseFloat(cur.amount)*100)/100,0)      
    return credit
    },
    debit(){
      let debit = (this.grossTotal*100 - this.credit*100)/100
      return debit
    },
    payMethods(){
      return this.$store.state.settings.payMethods
    }
  },
  filters: {
  toCurrency: function(value) {
    if(typeof value==='string') return value
    return value.toFixed(2)
  }
},
mounted(){
if(this.invoices&&this.invoices.length){
this.totalPaid = this.invoices.reduce((acc, cur)=> acc + cur.payments.reduce((acc2,cur2)=>acc2+cur2.amount,0), 0)
}
},
components: {
  datePicker,
},
}
</script>
<style scoped>
.isPaid{
  background: #9EC739;
}
</style>>
